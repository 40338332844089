.contentInner {
  margin: 0 auto;
  padding: 2rem 2rem;
  box-sizing: border-box;
}
.contentPara {
  display: block;
  margin: 0 auto;
  /* font-size: 1.2rem; */
  /* line-height: 2rem; */
}

.contentInner img {
  width: 100%;
  height: auto;
}

@media (max-width: 640px) {
  /* .activityTitle {
    font-size: 4.6rem;
    margin-top: -2.2rem;
  } */
}
