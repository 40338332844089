.header header {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

.home .header header {
  transition: background-color 0.5s;
}

.loading {
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  z-index: 1;
  width: 100%;
  /* background-image: url("/assets/logoload.svg"); */
  background-size: 52px 52px;
  background-repeat: no-repeat;
  background-position: center 49%;
}

.loading .MuiCircularProgress-root {
  width: 80px !important;
  height: 80px !important;
}

.headerLogo {
  height: 32px;
}

body,
.MuiTypography-root {
  font-family: "Overpass", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
h1,
h2,
strong,
button,
b,
h3,
h4,
h5,
h6 .MuiButton-label,
.MuiMenuItem-root,
.MuiButton-root {
  /* font-family: "Abril Fatface", cursive; */
  font-family: "Overpass", sans-serif !important;
  font-weight: 900 !important;
  font-style: italic;
}

p {
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

ul {
  margin: 0 !important;
  padding: 0 0 1rem 0;
}
ul {
  padding-left: 1.2rem;
}
ul li {
  padding-bottom: 0.4rem;
}

a {
  color: inherit;
}

.showsmall {
  display: none;
}

.divider {
  height: 56px;
}

.drawerlist .MuiCollapse-wrapper {
  border-left: solid;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .divider {
    height: 64px;
  }
}

.simplemenu {
  display: inline-block;
}

.MuiDrawer-paper {
  min-width: 260px;
}

.MuiButton-root {
  border-radius: 0 !important;
}

.lowest {
  background-color: #000;

  padding: 1rem;
  text-align: center;
  text-decoration: none;
  padding-bottom: 6rem;
}

.langBtn.selected {
  text-decoration: underline;
}

.custombtn {
  display: inline-block;
  padding: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #007993;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .showsmall {
    display: initial;
  }
  .hidesmall {
    display: none;
  }
  .headerLogo {
    height: 42px;
  }
  .bgWrapper {
    background-attachment: scroll !important;
  }
}

@media (max-width: 640px) {
  .headerLogo {
    height: 24px;
  }
}

.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}

.loading > div {
  margin: 0 auto;
}

.snip-layout {
  z-index: 2000;
}

.qualitycontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.qualitybox {
  text-align: center;
  padding: 0.5rem;
}

.qualitybox img {
  display: inline-block !important;
  width: 100px !important;
  opacity: 0.8;
}
.qualitybox p {
  font-size: 0.8rem;
  font-weight: bold;
}
.qualitybox > * {
  padding: 0;
}

.qualitybox h2 {
  background-color: #007993;
  color: #ffffff;
  width: 9rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 0 2rem 0;
  position: relative;
  font-size: 1.4rem;
}

select option {
  color: #333;
}
