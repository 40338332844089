.imagelinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
}

.imagelinks .imagelink {
  min-width: 300px;
  flex-grow: 1;
  text-align: center;
  height: 50vh;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
}

.imagelinks .imagelink:before {
  content: "";
  display: block;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  transform: rotate(90deg);
  opacity: 0;
  transform-origin: top right;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.imagelinks .imagelink > * {
  flex-grow: 1;
  font-size: 2rem;
  font-family: azo-sans-web, sans-serif;
  color: #fff;
  z-index: 1;
}

.imagelinks .imagelink:hover:before {
  transform: rotate(0deg);
  opacity: 0.6;
}

@media (max-width: 640px) {
  .imagelinks .imagelink {
    height: 33vh;
  }
}
