.imageAndTextElem img {
  width: 100%;
  display: block;
}

.imageAndTextElem {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  /* font-size: 1.2rem; */
  /* line-height: 1.6rem; */
}

.imageAndTextElem a {
  display: block;
}

.imageAndTextElem .text > div {
  max-width: 640px;
  padding: 1rem;
}

@media (max-width: 1024px) {
  .imageAndTextElem .text > div {
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    text-align: left;
  }
  .imageAndTextElem {
    font-size: 1rem;
    /* line-height: 1.6rem; */
  }
}

@media (max-width: 640px) {
  .imageAndTextElem .text > div {
    padding-bottom: 6rem;
  }
}
