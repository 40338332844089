.footerLogo {
  display: block;
  margin: 0 auto;
}

.footerInner {
  padding: 2rem 2rem 6rem 2rem;
  max-width: 1280px;
  margin: 0 auto;
}

.footerTitle {
  opacity: 0.6;
}

.footerLink:hover {
  opacity: 1;
}

.footerLink {
  opacity: 0.6;
}

.footerNav {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: solid;
  margin-top: 2rem;
}

.footerList li span {
  font-weight: bold !important;
}

.footerNav li,
.footerNav a {
  padding-bottom: 0;
  padding-top: 0;
}

@media (max-width: 1024px) {
  .footerList {
    width: 50%;
  }

  .footerList .MuiListItem-root {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .footerList {
    width: 100%;
  }
}
